<script setup lang="ts">
const { isSuperadmin, isAdmin, userData } = useUserState()
</script>

<template>
  <v-list>
    <v-divider />
    <v-list-item title="Briefings" prepend-icon="$mdiListbox" to="/briefings" />
    <v-divider />
    <v-list-item title="Demos" prepend-icon="$mdiDashboard" to="/demostraciones" />
    <v-divider />
    <v-list-item title="Recomendaciones" prepend-icon="$mdiAI" to="/insights" />
    <v-divider v-if="isSuperadmin" />
    <v-list-item v-if="isSuperadmin" title="FSBD Adify" prepend-icon="$mdiAIoutline" to="/adify/budget">
      <template v-slot:append>
        <v-badge dot inline color="success" />
      </template>
    </v-list-item>
    <v-divider />
    <v-list-item title="Contratar" prepend-icon="$mdiFileDocumentEdit" to="/contact" />
    <v-divider />
    <v-list-item v-if="isSuperadmin || isAdmin" title="Parámetros" prepend-icon="$mdiProgressCheck" to="/parameters" />
    <v-divider v-if="isSuperadmin || isAdmin" />
    <v-list-item v-if="isSuperadmin || isAdmin" title="Analytics" prepend-icon="$mdiAnalytics" to="/analytics" />
    <v-divider v-if="isSuperadmin || isAdmin" />
  </v-list>
</template>
